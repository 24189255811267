<template>
  <div>

    <!-- 3D   greenball  blueball-->
    <div class="res-open-content type-ssg pcddopen">
      <div class="r-o-f3d">
        <table class="com-table ">
          <tr>
            <th>期数</th>
            <th>时间</th>
            <th>佰位</th>
            <th>拾位</th>
            <th>个位</th>
            <th>总和</th>
            <th>跨度</th>
            <th> 佰大小 </th>
            <th> 佰单双 </th>
            <th> 佰质合 </th>
            <th> 拾大小 </th>
            <th> 拾单双 </th>
            <th> 拾质合 </th>
            <th> 个大小 </th>
            <th> 个单双 </th>
            <th> 个质合 </th>
          </tr>
          <tr v-for="item in list" :key="item.Expect">
            <td>{{item.Expect}}</td>
            <td>{{item.Opentime|substrSome}}</td>
            <td v-for="(code,i) in item.codeArr" :key="code" class="td-ball ">
              <!-- <p :class="'blueball b'+code"></p> -->
              <p  v-if="i<3" :class="'ball b'+i" :key="code+i">{{ code }}</p>
            </td>

            <td :class="fontColor[n]" v-for="(n,i) in item.lutuResult" :key="i">{{n}}</td>

          </tr>
        </table>
        <div class="page">
          <!-- :page-sizes="[20,50,100,200]" -->
          <el-pagination @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="total, prev, pager, next, jumper" :total="totals">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import kjmixins from "../mixins/kjmixins";
export default {
  name: "kjpl3",
  props: [""],
  mixins: [kjmixins],
  data() {
    return {};
  },

  components: {},
  created() {},
  computed: {},

  methods: {},
};
</script>
<style  scoped>
.pcddopen .ball:after {
   
   content: "";
 
}
.r-o-f3d .com-table tr td.ds_s,
.r-o-f3d .com-table tr td.dx_x,
.r-o-f3d .com-table tr td.zh_h {
  color: #00f;
}
.r-o-f3d .com-table tr td.ds_d,
.r-o-f3d .com-table tr td.dx_d,
.r-o-f3d .com-table tr td.zh_z {
  color: red;
}
</style>